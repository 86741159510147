import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const SharePost = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchPostDetail = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/post/publicGetPostDetail/${id}`
        );
        setPost(response.data.post);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching post details:", error);
        setLoading(false);
      }
    };
    fetchPostDetail();
  }, [id]);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === post.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? post.images.length - 1 : prevIndex - 1
    );
  };

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <div style={styles.spinner}>Loading...</div>
      </div>
    );
  }

  if (!post) {
    return (
      <div style={styles.notFoundContainer}>
        <p>Post not found.</p>
      </div>
    );
  }

  return (
    <div style={styles.pageContainer}>
      <header className="App-header">
        <div className="logo">
          <img src="./Logo.png" alt="Nexxy Logo" className="App-logo" />
        </div>
        <nav className="nav-links">
          <a href="#download">Download</a>
          <a href="#signup">Early Access</a>
        </nav>
      </header>
      {/* Download Nexxy Banner */}
      <div style={styles.downloadBanner}>
        <h2>Download Nexxy</h2>
        <p>Experience the full features by downloading the Nexxy app!</p>
      </div>

      <div style={styles.container}>
        <div style={styles.header}>
          <img
            src={post.userId.avatar}
            alt={`${post.userName}'s avatar`}
            style={styles.avatar}
          />
          <h3>{post.userName}</h3>
        </div>

        <p style={styles.content}>{post.content}</p>

        {/* Post Media */}
        {post.video && (
          <video controls style={styles.video} src={post.video}></video>
        )}

        {post.images && post.images.length > 0 && (
          <div style={styles.sliderContainer}>
            {post.images.length > 1 && (
              <button onClick={handlePreviousImage} style={styles.sliderButton}>
                &lt;
              </button>
            )}
            <img
              src={post.images[currentImageIndex]}
              alt={`Post image ${currentImageIndex + 1}`}
              style={styles.image}
            />
            {post.images.length > 1 && (
              <button onClick={handleNextImage} style={styles.sliderButton}>
                &gt;
              </button>
            )}
          </div>
        )}

        {/* Post Statistics */}
        <div style={styles.stats}>
          <p style={styles.statsText}>{post.heartsCount} Hearts</p>
        </div>
      </div>
    </div>
  );
};

// Styles for the component
const styles = {
  pageContainer: {
    padding: "20px",
    backgroundColor: "#f9f9f9",
  },
  downloadBanner: {
    backgroundColor: "#4A90E2",
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "8px",
    textAlign: "center",
    marginBottom: "20px",
  },
  container: {
    maxWidth: "600px",
    margin: "auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
  },
  avatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    marginRight: "12px",
  },
  content: {
    marginBottom: "20px",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#333",
  },
  video: {
    width: "100%",
    borderRadius: "8px",
    marginBottom: "16px",
  },
  sliderContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "16px",
  },
  sliderButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    border: "none",
    padding: "10px",
    cursor: "pointer",
    zIndex: 1,
  },
  image: {
    width: "100%",
    maxHeight: "400px",
    objectFit: "cover",
    borderRadius: "8px",
  },
  stats: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px",
    color: "gray",
    marginTop: "10px",
  },
  statsText: {
    margin: 0,
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
  spinner: {
    fontSize: "18px",
    color: "#888",
  },
  notFoundContainer: {
    textAlign: "center",
    marginTop: "40px",
    color: "gray",
  },
};

export default SharePost;
