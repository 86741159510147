import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Modal from "./Modal"; // Import the Modal component
import "./App.css"; // Reusing the same CSS as Home
import axios from "axios";

function Support() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [issue, setIssue] = useState("");
  const [issueType, setIssueType] = useState(""); // State to handle the issue type
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false); // Modal control
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform form validation
    if (!issueType) {
      setMessage("Please select an issue type.");
      setShowModal(true);
      return;
    }

    try {
      // Make an asynchronous POST request to the support endpoint
      const response = await axios.post(`${serverUrl}/web/support`, {
        email,
        name,
        issueType,
        issue,
      });

      // Handle successful submission
      setMessage("Support request submitted successfully.");
      setShowModal(true);

      // Clear the form fields
      setEmail("");
      setName("");
      setIssue("");
      setIssueType("");
    } catch (error) {
      // Handle error cases
      console.error("Error submitting the support request:", error);

      // Show an error message to the user
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setMessage(error.response.data.message);
      } else {
        setMessage("There was an error. Please try again.");
      }

      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="App">
      <Helmet>
        <title>Support - Nexxy</title>
        <meta
          name="description"
          content="Get support for Nexxy. Contact us for help with any issues or questions."
        />
        {/* Other meta tags */}
      </Helmet>

      <header className="App-header">
        <div className="logo">
          <img src="./Logo.png" alt="Nexxy Logo" className="App-logo" />
        </div>
        <nav className="nav-links">
          <a href="/">Home</a>
          <a href="/support">Support</a>
          <a href="/privacy">Privacy Policy</a>
        </nav>
      </header>

      <section className="Hero">
        <div className="Hero-content">
          <div className="text-content">
            <h1>Support</h1>
            <p>
              Need help? Our support team is here to assist you. If you have any
              issues, feel free to reach out to us.
            </p>
          </div>
          <div className="screenshots">
            <img
              src="./support.png"
              alt="Support Image"
              className="phone-image"
            />
          </div>
        </div>
      </section>

      <div className="Support">
        <h2>Contact Support</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <select
            value={issueType}
            onChange={(e) => setIssueType(e.target.value)}
            required
          >
            <option value="" disabled>
              Select Issue Type
            </option>
            <option value="Bug">Bug</option>
            <option value="Feature Request">Feature Request</option>
            <option value="General Inquiry">General Inquiry</option>
            <option value="Account Issue">Account Issue</option>
            <option value="Login/Password Issue">Login/Password Issue</option>
            <option value="Technical Support">Technical Support</option>
            <option value="Performance Issue">Performance Issue</option>
            <option value="Data Privacy Concern">Data Privacy Concern</option>
            <option value="Service/App Not Available">
              Service/App Not Available
            </option>
            <option value="Feature Not Working">Feature Not Working</option>
            <option value="Feedback/Suggestion">Feedback/Suggestion</option>
            <option value="Account Deletion Request">
              Account Deletion Request
            </option>
            <option value="Security Concern">Security Concern</option>
            <option value="Other">Other</option>
          </select>

          <textarea
            placeholder="Describe your issue"
            rows="5"
            value={issue}
            onChange={(e) => setIssue(e.target.value)}
            required
          ></textarea>
          <button type="submit">Submit</button>
        </form>
      </div>

      <footer>
        <p>&copy; 2024 Nexxy LLC. All rights reserved.</p>
        <nav>
          <a href="/privacy">Privacy Policy</a>
          <a href="/tos">Terms of Service</a>
          <a href="/community-guidelines">Community Guidelines</a>
        </nav>
      </footer>

      {/* Show Modal when showModal is true */}
      {showModal && <Modal message={message} closeModal={closeModal} />}
    </div>
  );
}

export default Support;
