import React, { useState } from "react";
import axios from "axios";
import Modal from "./Modal"; // Import the Modal component
import "./App.css";
import { Helmet } from "react-helmet";

function Home() {
  const [email, setEmail] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [platform, setPlatform] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!platform) {
      setMessage("Please select your platform (iOS or Android).");
      setShowModal(true);
      return;
    }

    axios
      .post(`${serverUrl}/web/signup`, {
        email,
        platform,
        agreedToEmails: agreed,
      })
      .then((response) => {
        setMessage("Success! You've been added to the waitlist.");
        setShowModal(true);
      })
      .catch((error) => {
        setMessage("There was an error. Please try again.");
        setShowModal(true);
      });

    // Clear the form
    setEmail("");
    setAgreed(false);
    setPlatform("");
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="App">
      <Helmet>
        <title>Nexxy</title>
        <meta
          name="description"
          content="Join Nexxy, Connect, share, and explore like never before."
        />
        <meta
          name="keywords"
          content="Nexxy, social media, connect, share, explore, social networking"
        />
        <meta name="author" content="Nexxy LLC" />
        <link rel="canonical" href="https://www.nexxyapp.com" />

        <meta property="og:title" content="Nexxy" />
        <meta
          property="og:description"
          content="Join Nexxy for a new social media experience. Connect, share, and explore like never before."
        />
        <meta property="og:image" content="path-to-social-media-image.png" />
        <meta property="og:url" content="https://www.nexxyapp.com" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nexxy" />
        <meta name="twitter:description" content="Nexxy. Join today." />
        <meta name="twitter:image" content="path-to-social-media-image.png" />

        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebSite",
              "url": "https://www.nexxyapp.com",
              "name": "Nexxy",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://www.nexxyapp.com/search?q={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            }
          `}
        </script>
      </Helmet>

      <header className="App-header">
        <div className="logo">
          <img src="./Logo.png" alt="Nexxy Logo" className="App-logo" />
        </div>
        <nav className="nav-links">
          <a href="#download">Download</a>
          <a href="#signup">Early Access</a>
        </nav>
      </header>

      <section className="Hero">
        <div className="Hero-content">
          <div className="text-content">
            <h1>Nexxy</h1>
            <p>Join us or miss out. The coolest things are happening here.</p>
            <div className="cta-buttons">
              <button>App Store</button>
              <button>Play Store</button>
            </div>
          </div>
          <div className="screenshots">
            <img
              src="./AppDesign.png"
              alt="Nexxy App Screens"
              className="phone-image"
            />
          </div>
        </div>
      </section>

      {showModal && <Modal message={message} closeModal={closeModal} />}

      <section className="Signup">
        <h2>Join to get early access!</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="platform-buttons">
            <button
              type="button"
              className={platform === "iOS" ? "active" : ""}
              onClick={() => setPlatform("iOS")}
            >
              iOS
            </button>
            <button
              type="button"
              className={platform === "Android" ? "active" : ""}
              onClick={() => setPlatform("Android")}
            >
              Android
            </button>
          </div>
          <div className="checkbox">
            <input
              type="checkbox"
              checked={agreed}
              onChange={(e) => setAgreed(e.target.checked)}
              required
            />
            <label>I agree to be sent emails about Nexxy.</label>
          </div>
          <button type="submit" disabled={!agreed || !platform}>
            Join
          </button>
        </form>
      </section>
      <footer>
        <p>&copy; 2024 Nexxy LLC. All rights reserved.</p>
        <nav>
          <a href="/support">Support</a>
          <a href="/privacy">Privacy Policy</a>
          <a href="/tos">Terms of Service</a>
          <a href="/community-guidelines">Community GuideLines</a>
        </nav>
      </footer>
    </div>
  );
}

export default Home;
