import React from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import { Link } from "react-router-dom";

function Tos() {
  return (
    <div className="App">
      <Helmet>
        <title>Nexxy - Terms of Service</title>
        <meta
          name="description"
          content="Nexxy Terms of Service - Learn the rules and guidelines for using our social media platform."
        />
        <meta
          name="keywords"
          content="Nexxy, terms of service, rules, guidelines, social networking"
        />
        <meta name="author" content="Nexxy LLC" />
        <link rel="canonical" href="https://www.nexxyapp.com/terms" />

        <meta property="og:title" content="Nexxy - Terms of Service" />
        <meta
          property="og:description"
          content="Learn the rules and guidelines for using Nexxy, a social media platform."
        />
        <meta property="og:image" content="path-to-social-media-image.png" />
        <meta property="og:url" content="https://www.nexxyapp.com/terms" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nexxy - Terms of Service" />
        <meta
          name="twitter:description"
          content="Nexxy Terms of Service. Learn the rules and guidelines for using our platform."
        />
        <meta name="twitter:image" content="path-to-social-media-image.png" />

        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://www.nexxyapp.com/terms",
              "name": "Nexxy - Terms of Service"
            }
          `}
        </script>
      </Helmet>

      <header className="App-header">
        <div className="logo">
          <Link to="/">
            <img src="./Logo.png" alt="Nexxy Logo" className="App-logo" />
          </Link>
        </div>
        <nav className="nav-links">
          <Link to="/">Download</Link>
          <Link to="/">Wait List</Link>
        </nav>
      </header>

      <section className="TermsOfService">
        <h2>Terms of Service</h2>
        <p>
          <strong>Effective Date:</strong> 9/20/24
        </p>

        <h3>1. Introduction</h3>
        <p>
          Welcome to Nexxy, a social media application provided by Nexxy LLC. By
          accessing or using our services on iOS, Android, or the web, you agree
          to be bound by these Terms of Service (the "Terms"). Please read these
          Terms carefully. By using Nexxy, you also agree to adhere to our{" "}
          <Link to="/community-guidelines">Community Guidelines</Link>.
        </p>

        <h3>2. Eligibility</h3>
        <p>
          To use Nexxy, you must be at least 16 years old. By using the app, you
          confirm that you meet this age requirement.
        </p>

        <h3>3. Account Registration</h3>
        <p>
          You may need to create an account to access certain features of Nexxy.
          When creating an account, you agree to provide accurate and complete
          information, including your name, email address, and birthday. You are
          responsible for maintaining the confidentiality of your account and
          password.
        </p>

        <h3>4. User Conduct</h3>
        <p>You agree not to:</p>
        <ul>
          <li>
            Upload, post, or share any content that is pornographic, sexually
            explicit, or otherwise violates our content policies.
          </li>
          <li>
            Engage in any unlawful, harmful, or abusive behavior while using
            Nexxy.
          </li>
          <li>
            Impersonate any person or entity, or falsely state or otherwise
            misrepresent your affiliation with a person or entity.
          </li>
        </ul>

        <h3>5. Content Ownership and License</h3>
        <p>
          You retain ownership of any content you post on Nexxy. However, by
          posting content, you grant Nexxy a worldwide, non-exclusive,
          royalty-free license to use, distribute, and display your content in
          connection with providing our services.
        </p>

        <h3>6. Account and Content Suspension or Termination</h3>
        <p>
          Nexxy reserves the right to suspend or terminate your account, or
          remove or suspend any of your posts, at any time, for any reason,
          including if we deem your content or behavior to be harmful, or no
          reason at all, without prior notice.
        </p>

        <h3>7. Advertising</h3>
        <p>
          Nexxy uses its own proprietary advertising platform to display
          advertisements. By using the app, you consent to the display of ads
          that may be tailored based on your interactions with the app, as well
          as other data you provide. These ads are designed to enhance your
          experience while using Nexxy.
        </p>

        <h3>8. Data Storage and Security</h3>
        <p>
          We store user data, including your name, email address, IP address,
          and hashed password, in MongoDB. Images are stored in Google Buckets,
          and our backend is hosted on Google Cloud. While we take appropriate
          security measures to protect your data, you acknowledge that no system
          is completely secure.
        </p>

        <h3>9. Push Notifications</h3>
        <p>
          Nexxy uses NativeNotify to send push notifications. By creating an
          account, you consent to receiving push notifications, which you can
          manage in your account settings.
        </p>

        <h3>10. Compliance with Laws</h3>
        <p>
          You agree to comply with all applicable laws, including but not
          limited to those related to data protection and privacy.
        </p>

        <h3>11. Verification Process</h3>
        <p>
          Applying for verification on Nexxy is free. We reserve the right to
          approve or deny verification requests at our discretion. If your
          verification request is denied, we will provide a reason for the
          denial. All verification requests can take a few months to be
          processed.
        </p>

        <h3>12. Governing Law</h3>
        <p>
          These Terms are governed by the laws of the State of California,
          without regard to its conflict of law principles.
        </p>

        <h3>13. Changes to the Terms</h3>
        <p>
          We may modify these Terms at any time. Your continued use of Nexxy
          after any changes indicates your acceptance of the new Terms.
        </p>
        <h3>14. User-Generated Content and Moderation</h3>
        <p>
          Nexxy allows users to create, post, and share content. You are solely
          responsible for the content you post on the platform. Nexxy reserves
          the right to review, monitor, and remove any user-generated content
          that we deem inappropriate, harmful, offensive, or that violates our{" "}
          <Link to="/community-guidelines">Community Guidelines</Link>. This
          includes, but is not limited to, posts containing hate speech,
          harassment, explicit material, or misinformation.
        </p>
        <h3>15. User Privacy and Data Usage</h3>
        <p>
          Nexxy collects and processes user data in accordance with our
          <Link to="/privacy">Privacy Policy</Link>. We respect your privacy and
          will never share your personal data with third parties without your
          consent, except as required by law. By using Nexxy, you agree to the
          collection and use of your data as outlined in our privacy policy.
        </p>
        <h3>16. Third-Party Content and Links</h3>
        <p>
          Nexxy may contain links to third-party websites, applications, or
          services that are not owned or controlled by Nexxy. We are not
          responsible for any third-party content, and your interactions with
          such third parties are at your own risk. We do not endorse or assume
          any responsibility for any third-party content or services.
        </p>
        <h3>17. Intellectual Property Rights</h3>
        <p>
          All trademarks, logos, and service marks displayed on Nexxy are the
          property of Nexxy LLC. You agree not to use, modify, or distribute our
          intellectual property without our express written consent. By posting
          content on Nexxy, you warrant that you have the right to share such
          content and that it does not infringe the intellectual property rights
          of any third party.
        </p>
        <h3>18. Disclaimer of Warranties</h3>
        <p>
          Nexxy is provided on an "as is" and "as available" basis. We do not
          guarantee that Nexxy will be available at all times or without
          interruption. Nexxy disclaims all warranties, whether express or
          implied, including but not limited to warranties of merchantability,
          fitness for a particular purpose, and non-infringement.
        </p>
        <h3>19. Limitation of Liability</h3>
        <p>
          To the maximum extent permitted by law, Nexxy LLC, its affiliates,
          employees, directors, agents, licensors, and partners shall not be
          liable for any direct, indirect, incidental, special, or consequential
          damages arising out of or in connection with the use of Nexxy,
          including but not limited to damages for loss of profits, goodwill,
          data, or other intangible losses, whether based on breach of contract,
          tort (including negligence), product liability, or otherwise.
        </p>
        <p>
          This limitation of liability applies to any losses or damages
          resulting from:
          <ul>
            <li>
              Your use or inability to use Nexxy, including any errors, bugs, or
              downtime of the platform.
            </li>
            <li>
              Unauthorized access to or alteration of your transmissions, data,
              or account.
            </li>
            <li>
              Any conduct or content of any third party, including but not
              limited to any defamatory, offensive, or illegal conduct of other
              users or third parties.
            </li>
            <li>
              Any content or data you submit, post, transmit, or share on or
              through Nexxy.
            </li>
          </ul>
        </p>
        <p>
          By using Nexxy, you agree that any claim or cause of action arising
          out of or related to the use of Nexxy must be filed within one (1)
          year after such claim or cause of action arose, or it will be
          permanently barred.
        </p>
        <p>
          You agree that your use of Nexxy is at your own risk and that Nexxy is
          provided on an "as is" and "as available" basis, without any
          warranties of any kind, either express or implied, including but not
          limited to implied warranties of merchantability, fitness for a
          particular purpose, and non-infringement.
        </p>
        <p>
          Some jurisdictions do not allow the exclusion of certain warranties or
          the limitation or exclusion of liability for incidental or
          consequential damages, so the limitations and exclusions in these
          Terms may not apply to you. In such cases, Nexxy LLC’s liability will
          be limited to the fullest extent permitted by applicable law.
        </p>
        <p>
          In no event shall the aggregate liability of Nexxy LLC exceed the
          amount you have paid Nexxy LLC, if any, in the past twelve (12) months
          for the services giving rise to the claim.
        </p>
        <p>
          You hereby release Nexxy LLC, its affiliates, employees, directors,
          agents, licensors, and partners from any and all claims, demands, and
          damages of every kind and nature, known and unknown, arising out of or
          related to any dispute with any other user or third party.
        </p>

        <h3>20. User-Generated Contests or Promotions</h3>
        <p>
          Nexxy may allow users to create and promote their own contests or
          giveaways. These promotions are the sole responsibility of the user
          organizing them. Nexxy is not liable for any claims arising from
          user-generated promotions or contests, including fulfillment of prizes
          or adherence to local regulations.
        </p>
        <h3>21. Reporting Violations</h3>
        <p>
          If you believe a user has violated these Terms of Service or our
          <Link to="/community-guidelines">Community Guidelines</Link>, you can
          report the content or user through the in-app reporting feature or by
          contacting our support team at{" "}
          <a href="mailto:support@nexxyapp.com">support@nexxyapp.com</a>.
        </p>
        <h3>14. Username Policy</h3>
        <p>
          Your username on Nexxy should adhere to our
          <Link to="/community-guidelines">Community Guidelines</Link>. Nexxy
          reserves the right to modify or change usernames at any time, without
          notice, if your username is found to violate these guidelines, be
          inappropriate, misleading, or otherwise harmful to the Nexxy
          community.
        </p>
      </section>

      <footer>
        <p>&copy; 2024 Nexxy LLC. All rights reserved.</p>
        <nav>
          <a href="/support">Support</a>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/community-guidelines">Community Guidelines</Link>
        </nav>
      </footer>
    </div>
  );
}

export default Tos;
